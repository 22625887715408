define(['app',
  'siteObj',
  'module', 'enhancedEcom'], function(app, siteObj, module, enhancedEcom) {

  var productAddToBasket = function() {
    var component = this;

    var _config = {
      dataAttributes: {
        productIdSelector: 'data-product-id',
        childId: 'data-child-id',
        siteIdSelector: 'data-site-id',
        fromWishlist: 'data-from-wishlist',
        defaultLocaleSelector: 'data-default-locale',
        subsiteCodeSelector: 'data-subsite-code',
        eGiftSelector: 'data-eGift',
        headerBasketSelector: 'header-basket',
        basketItemsSelector: 'basket-items',
        basketQuantityClass: 'header-menu-basket-counter',
        skipAddedToBasket: 'data-skip',
        context: 'data-context',
        wishlistPage: 'data-wishlist-page',
        linkedProduct: 'data-linked-product',
        subscriptionContractId: 'data-subscription-contract-id',
        purchaseMethod: 'data-basket-purchase-method',
        fulfilmentType: 'data-fulfilment-type',
        storeID: 'data-store-id',
        hasEnhancedEcomAtt: 'data-has-enhanced-ecom',
        hasFulfilmentMethod: 'data-has-fulfilment-method',
        personalisedProduct: 'data-is-personalised-product',
        personalisationValid: 'data-personalisation-valid',
        personalisationTab: 'data-personalisation-tab',
        fontId: 'data-font-id',
        personalisationFieldName: 'data-field-name',
        stickyTop: 'cta-sticky-top',
        stickyBottom: 'cta-sticky-bottom',
        variantScroll: 'data-variant-scroll-trigger',
        soldOut: 'data-sold-out',
      },
      selectors: {
        productVariations: '[data-variation-container=productVariations]',
        individualVariation: '.individualVariation',
        iRazor: '.productAddToBasket-iRazor',
        productPromotionalMessage: '#productPromotionalMessage',
        productPageStockAvailabilityModal: '.productPageStockAvailabilityModal',
        personalisableProduct: '[data-component=personalisationSelect]',
        selectedFont: '[data-selected-personalisation-font=true]',
        personalisationInputField: '[data-personalisation-input]',
        personalisationDropdownButton: '.personalisation_dropdown_button',
        productPageproductVariant: '.productPage_productVariations_selector',
        stickyCTAErrorMessage: '.stickycta_error_message',
        athenaProductVariationsDropdownLabel: '.athenaProductVariations_dropdownLabel',
        productVariationsDropdownLabel: '.productVariations_dropdownLabel',
        stickyButtonDelivery: '[cta-sticky-button-delivery]'
      },
      classes: {
        showErrorMessage: 'show',
        changeErrorMessageFont: 'error_message',
      },
      ids: {
        productPromotionalMessage: 'productPromotionalMessage',
      },
      subscribeChannels: {
        variations: 'productVariations/variationData',
        quantity: 'productQuantityInput/quantityData',
        addedToBasket: 'productAddedToBasket/loadSuccess',
        eGift: 'producteGift/eGift',
        eGiftAddToBasket: 'producteGift/addToBasket',
        subscribeAndSaveContracts: 'subscribeAndSaveContracts/changeContractId',
        subscribeAndSaveDisableBasket: 'productAddToBasket/disableBasket',
        personalisationStateUpdated: 'productPersonalisation/inputStateUpdate',
        validPersonalisation: 'personalisation/message',
        subscribeDefault: 'subscribeAndSave/subscribeDefault'
      },
      publishChannels: {
        subscribeAndSaveProductInBasketMessage: 'subscribeAndSaveProductInBasket/showMessage',
        productQuantityDisable: 'productQuantityInput/disable',
        personalisationGetState: 'productPersonalisation/getInputState',
      },
      stateObject: {
        variationTitle: 'variation'
      },
      soldOutClass: 'productAddToBasket-soldOut',
      personalisationState: {
        name: null,
        message: null
      }
    };

    var _init = function (element, productId) {
      component.element = element;

      component.personalisedProduct = false;

      if (component.element) {
        component.parentElement = component.element.parentElement.parentElement;
        component.quantity = {
          value: 1,
          max: 99,
          inBasket: 0,
        };

        if (component.parentElement) {
          component.allVariations = component.parentElement.querySelectorAll(component.config.selectors.individualVariation);
          component.wishlistPage = component.parentElement.getAttribute(component.config.dataAttributes.wishlistPage);
          component.linkedProduct = component.parentElement.getAttribute(component.config.dataAttributes.linkedProduct);
          component.productId = component.parentElement.getAttribute(component.config.dataAttributes.productIdSelector);
          component.childProductId = component.element.getAttribute(component.config.dataAttributes.productIdSelector);
          component.iRazor = component.parentElement.querySelector(component.config.selectors.iRazor);
          component.subscriptionContractId = component.parentElement.getAttribute(component.config.dataAttributes.subscriptionContractId);
          component.fulfilmentType = component.parentElement.getAttribute(component.config.dataAttributes.fulfilmentType);
          component.storeID = component.parentElement.getAttribute(component.config.dataAttributes.storeID);
          component.productPageStockAvailabilityModal= document.querySelector(component.config.selectors.productPageStockAvailabilityModal);

          if (component.linkedProduct === 'true') {
            if (productId) {
              component.productId = productId;
            }
          }

          component.siteId = component.parentElement.getAttribute(component.config.dataAttributes.siteIdSelector);
          component.defaultLocale = component.parentElement.getAttribute(component.config.dataAttributes.defaultLocaleSelector);
          component.subsiteCode = component.parentElement.getAttribute(component.config.dataAttributes.subsiteCodeSelector);
          component.eGift = component.element.getAttribute(component.config.dataAttributes.eGiftSelector);
          component.skipAddedToBasket = component.element.getAttribute(component.config.dataAttributes.skipAddedToBasket);
          component.context = component.parentElement.getAttribute(component.config.dataAttributes.context);
          component.fromWishlist = component.parentElement.getAttribute(component.config.dataAttributes.fromWishlist);
          component.personalisedProduct = component.parentElement.hasAttribute(component.config.dataAttributes.personalisedProduct);

        }

        if (component.wishlistPage === 'true') {
          component.variationReceived(
            component.buildVariations());
        } else {
          var variationSubscribe = app.subscribe(component.config.subscribeChannels.variations, component.variationReceived);
          component.checkSubscribeMessages(variationSubscribe, component.variationReceived);
        }

        var quantitySubscribe = app.subscribe(component.config.subscribeChannels.quantity, component.quantityReceived);
        component.checkSubscribeMessages(quantitySubscribe, component.quantityReceived);

        app.subscribe(component.config.subscribeChannels.subscribeAndSaveContracts, component.subscriptionContractsChangeContractId);

        app.subscribe(component.config.subscribeChannels.subscribeAndSaveDisableBasket, component.subscribeAndSaveAddToBasket);

        if (component.hasProductPersonalisation()) {
          app.clear(component.config.subscribeChannels.validPersonalisation);
          app.subscribe(component.config.subscribeChannels.validPersonalisation, component.personalisationReceived);
        }

        if (component.element.hasAttribute(component.config.dataAttributes.purchaseMethod)) {
          component.purchaseMethod = component.element.getAttribute(component.config.dataAttributes.purchaseMethod);
          app.publish(component.config.publishChannels.subscribeAndSaveProductInBasketMessage, component.purchaseMethod);
        }

        if (component.eGift) {
          app.clear(component.config.subscribeChannels.eGift);
          app.subscribe(component.config.subscribeChannels.eGift, component.eGiftReceived);
          component.element.addEventListener('click', component.egiftAddToBasket);
        } else if (component.skipAddedToBasket) {
          component.element.addEventListener('click', component.triggerAddStraightToBasket);
        } else if (component.iRazor){
          component.element.addEventListener('click', component.customiseiRazor);
        } else if (siteObj.type === "product" && (((siteObj.config.enableStickyTopCTAAddToBasket === 'true') || (siteObj.config.enableStickyBottomCTAAddToBasket === 'true')) && (component.element.hasAttribute(component.config.dataAttributes.stickyTop) || component.element.hasAttribute(component.config.dataAttributes.stickyBottom)) && component.element.hasAttribute(component.config.dataAttributes.variantScroll) && !component.element.hasAttribute(component.config.dataAttributes.productIdSelector) && ((document.querySelector(component.config.selectors.productPageproductVariant).firstElementChild.firstElementChild.dataset.hasSelection) === 'false'))) {
          component.element.addEventListener('click', component.scrollToVariantSelector);
        }
        else if (siteObj.type === "product" && ((siteObj.config.enableStickyTopCTAAddToBasket === 'true') || (siteObj.config.enableStickyBottomCTAAddToBasket === 'true')) && (component.element.classList.contains('complexProduct') && !component.element.parentElement.parentElement.parentElement.classList.contains('productQuickbuy_productAddToBasket') && !component.element.hasAttribute(component.config.dataAttributes.productIdSelector) && ((document.querySelector(component.config.selectors.productPageproductVariant).firstElementChild.firstElementChild.dataset.hasSelection) === 'false'))) {
          component.element.addEventListener('click',  component.showErrorMessage);
        }
         else {
          component.element.addEventListener('click', component.triggerAddToBasket);
        }

        if(component.personalisedProduct){
          app.subscribe(component.config.subscribeChannels.personalisationStateUpdated, component.handlePersonalisationStateUpdate);
          app.publish(component.config.publishChannels.personalisationGetState);
        }
      }

      component.basketHeaderClass = module.config().basketHeaderClass || component.config.dataAttributes.headerBasketSelector;
      component.basketHeaderID = module.config().basketHeaderID || false;
      component.basketItemsContainerClass = module.config().basketItemsContainerClass || component.config.dataAttributes.basketItemsSelector;

      if(component.element) {
        if (component.element.hasAttribute('disabled') && !component.element.classList.contains(component.config.soldOutClass)) {
          component.element.setAttribute('disabled', false);
        }

        if (document.querySelector(component.config.selectors.productPromotionalMessage)) {
          component.element.setAttribute('aria-describedby', component.config.ids.productPromotionalMessage);
        }
      }

      app.publish(component.config.subscribeChannels.subscribeDefault);

      app.subscribe('addProductToBasket/exclusive', component.addProductToBasketExclusive);

      return component;
    };

    component.addProductToBasketExclusive = () => {
      component.triggerAddToBasket();
      if(component.childProductId) {
        app.publish('tracking/record', 'Product | Add to Basket - exclusive product', 'success', component.childProductId);
      }
      else {
        app.publish('tracking/record', 'Product | Add to Basket - exclusive product', 'success', component.productId);
      }
    }

    component.hasProductPersonalisation = () => {
      return !!document.querySelector(component.config.selectors.personalisableProduct);
    };

    var _scrollToVariantSelector= function (){
      var productVariant = document.querySelector(component.config.selectors.productPageproductVariant);

      if(productVariant){
        productVariant.scrollIntoView({behavior: "smooth", block: 'center' });
      }

      component.showErrorMessage();

    }

    var _showErrorMessage = function () {
      document.querySelector(component.config.selectors.stickyCTAErrorMessage).classList.add(component.config.classes.showErrorMessage);
      var variationlabel = document.querySelector(component.config.selectors.athenaProductVariationsDropdownLabel)
      || document.querySelector(component.config.selectors.productVariationsDropdownLabel);
      variationlabel.classList.add(component.config.classes.changeErrorMessageFont);

      if((siteObj.config.enableStickyBottomCTAAddToBasket === 'true') && component.element.hasAttribute(component.config.dataAttributes.stickyBottom)) {
        app.publish('tracking/record', 'Product | Variant selected - bottom page', 'success', component.productId);
      }
      else if((siteObj.config.enableStickyTopCTAAddToBasket === 'true') && component.element.hasAttribute(component.config.dataAttributes.stickyTop) ) {
        app.publish('tracking/record', 'Product | Variant selected - top page', 'success', component.productId);
      }
      else {
        app.publish('tracking/record', 'Product | SelectOption', 'success', component.productId);
      }
    }

    var _customiseiRazor = function() {
      component.iRazor.href += ('&qty=' + component.quantity.value);
    };

    var _variationReceived = function({variationLength, variations, isVariationPersonalisable}) {
      component.variationsObject = {
        variationLength,
        variations,
        isVariationPersonalisable
      };

      if (component.canAdd()) component.enableAddToBasket();
      else component.disableAddToBasket();
    };

    var _quantityReceived = function({value, max, inBasket}) {
      component.quantity = {
        value: ((value > 0) && (value < 100)) ? value : 1,
        max,
        inBasket,
      };
      if (component.canAdd()) component.enableAddToBasket();
      else component.disableAddToBasket();
    };

    component.personalisationReceived = (engravingTab, validPersonalisation) => {
      component.parentElement.setAttribute(component.config.dataAttributes.personalisationTab, engravingTab);
      component.parentElement.setAttribute(component.config.dataAttributes.personalisationValid, validPersonalisation);

      if (component.canAdd()) component.enableAddToBasket();
      else component.disableAddToBasket();
    };

    var _canAdd = function() {
      const {quantity, variationsObject} = component;
      if (!quantity || !variationsObject) return false;
      if (app.element.hasClass(component.config.soldOutClass, component.element)) return false;
      if (quantity.max && quantity.inBasket && quantity.value + quantity.inBasket > quantity.max) return false;
      if (variationsObject.variations && (variationsObject.variationLength !== variationsObject.variations.length)) return false;
      if (variationsObject.variations && variationsObject.variations.some(v => !v)) return false;
      if (component.hasProductPersonalisation() && component.getIsPersonalisationTab() && !component.getIsPersonalisationValid()) return false;
      if (component.personalisedProduct && !component.isValidPersonalisationState()) return false;
      return true;
    };

    component.getIsPersonalisationTab = () => {
      return (component.parentElement.getAttribute(component.config.dataAttributes.personalisationTab) === 'true');
    };

    component.getIsPersonalisationValid = () => {
      let validVariation = true;
      if(component.variationsObject.isVariationPersonalisable){
        for(let i=0; i<component.variationsObject.isVariationPersonalisable.length; i++){
          if(component.variationsObject.isVariationPersonalisable[i] === 'false'){
            validVariation = false;
          }
        }
      }else{
        validVariation = false;
      }

      const validPersonalisation = (component.parentElement.getAttribute(component.config.dataAttributes.personalisationValid) === 'true');
      return (validVariation && validPersonalisation);
    };

    var _enableAddToBasket = function() {
      component.element.disabled = false;
    };

    var _disableAddToBasket = function() {
      if(((siteObj.config.enableStickyTopCTAAddToBasket === 'true') || (siteObj.config.enableStickyBottomCTAAddToBasket === 'true')) && !component.element.hasAttribute(component.config.dataAttributes.soldOut)) {
        component.enableAddToBasket();
      }
      else{
        component.element.disabled = true;
      }
    };

    var _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };


    var _triggerAddToBasket = function(event) {
      if (component.productPageStockAvailabilityModal !== null) {
        component.productPageStockAvailabilityModal.parentElement.parentElement.classList.remove('modal_dialog-show');
      }

      if(component.personalisedProduct && !component.isValidPersonalisationState()) return false;

      app.publish('addedToBasketModal/hideError', '', false);
      app.publish('productQuickbuy/hideError', '', false);
      app.publish('productQuickbuy/startLoading', '', false);
      app.publish('storeIdDisplay', event);

      if (component.childProductId) {
        app.publish('tracking/record', 'Product | AddToBasket', 'clicked', component.productId, component.childProductId);
      } else {
        app.publish('tracking/record', 'Product | AddToBasket', 'clicked', component.productId);
      }

      const stickyButtonParent = component.parentElement.parentElement;
      if (stickyButtonParent && stickyButtonParent.classList.contains('stickyButton')) {
        app.publish('columbo/track', 'sticky-delivery-button', 'click');
      }

      component.postAddToBasket(
        component.querystringifyStateObject(
          component.createStateObject()
        )
      );
    };

    var _triggerAddStraightToBasket = function() {
      var variationsEl = document.querySelector(component.config.selectors.productVariations);
      var childId = variationsEl.getAttribute(component.config.dataAttributes.childId);
      window.location.href = `/my.basket?buylist=${childId}:1`;
    };

    var _eGiftReceived = function(eGiftData) {
      var querystring = component.querystringifyStateObject(
        component.createStateObject());
      if (querystring && querystring.length > 0 && eGiftData) {
        if (component.element.baseURI.includes('worthByPrice') || component.element.baseURI.includes('noWorth')) {
          querystring = querystring + '&worthValue=worthByPrice';
        }
        app.ajax.post({
          url: '/basketinterface.json?' + querystring,
          send: JSON.stringify(eGiftData),
          success: component.addToBasketSuccess,
          error: component.eGiftError
        });
      }
    };

    var _eGiftError = function() {
      app.publish('producteGift/eGiftError', '');
    };

    var _postAddToBasket = function(querystring) {
      if (querystring && querystring.length > 0) {
        if (component.element.baseURI.includes('worthByPrice') || component.element.baseURI.includes('noWorth')) {
          querystring = querystring + '&worthValue=worthByPrice';
        }

        app.ajax.get({
          url: '/basketinterface.json?' + querystring,
          success: component.addToBasketSuccess,
          error: component.addToBasketError
        });
      }
    };

    var _addToBasketError = function() {
      app.publish('productQuickbuy/stopLoading', '', false);
      app.publish('productQuickbuy/showError', '', false);
      app.publish('addedToBasketModal/showError', '', false);
    };

    var _addToBasketSuccess = function(data) {
      var jsonData = JSON.parse(data);
      var modalData = component.getLastAddedItemData(jsonData.simpleBasket);

      //if modalData has a contractId then set the purchaseMethod to be subscription
      //if the contractId is null -> set attribute to 'otp'
      if (modalData.subscriptionContractId) {
        component.element.setAttribute(component.config.dataAttributes.purchaseMethod, 'subscription');
        app.publish(component.config.publishChannels.subscribeAndSaveProductInBasketMessage, 'subscription');
      } else {
        component.element.setAttribute(component.config.dataAttributes.purchaseMethod, 'otp');
        app.publish(component.config.publishChannels.subscribeAndSaveProductInBasketMessage, 'otp');
      }

      app.publish('productQuickbuy/hideModal', '', false);
      app.publish('addedToBasketModal/productAdded', modalData, false);
      app.publish('globalBasketItemsCount/updateGlobalBasketItemsCount', modalData.basketTotalItems);
      app.publish('BasketItemAdded', jsonData);

      if(component.personalisedProduct) {
        app.publish('ProductPersonalisationModal/closeModal');
      }

      if(siteObj.config.useGa4EnhancedEcom === true) {
        const lastAddedItem = jsonData.simpleBasket.lastAddedItem;
        const productTitle = lastAddedItem.productTitle.toString().replace(/&#039;|'/g, "")
        const price = component.formattedPrice(lastAddedItem.price);
        const discount = component.formattedPrice(lastAddedItem.savingsAmount);
        const value = component.formattedPrice(jsonData.simpleBasket.totalprice);
        const ga4Data = {
          'event': 'ecom_event',
          'event_name': 'add_to_cart',
          'value': value,
          'ecommerce': {
            'currency': siteObj.currencyType,
            'items': [{
              'item_name': productTitle,
              'item_id' : lastAddedItem.productId.toString(),
              'parent_item_id': lastAddedItem.childMasterProductId,
              'price': price,
              'item_brand': lastAddedItem.brand,
              'quantity' : jsonData.simpleBasket.lastAddedQuantity,
              'item_category' : lastAddedItem.category,
              'discount': discount,
            }]
          }
        }

        app.publish('record/general', ga4Data)
      }

      if((siteObj.config.enableStickyBottomCTAAddToBasket === 'true') &&  component.element.hasAttribute(component.config.dataAttributes.stickyBottom)) {
        if(component.childProductId) {
          app.publish('tracking/record', 'Product | Add to Basket - bottom page', 'success', component.childProductId);
        }
        else {
          app.publish('tracking/record', 'Product | Add to Basket - bottom page', 'success', component.productId);
        }
      }
      else if((siteObj.config.enableStickyTopCTAAddToBasket === 'true') &&  component.element.hasAttribute(component.config.dataAttributes.stickyTop)) {
        if(component.childProductId) {
          app.publish('tracking/record', 'Product | Add to Basket - top page', 'success', component.childProductId);
        }
        else {
          app.publish('tracking/record', 'Product | Add to Basket - top page', 'success', component.productId);
        }
      }
      else {
        if (component.childProductId) {
          app.publish('tracking/record', 'Product | AddToBasket', 'success', component.childProductId, jsonData.simpleBasket.requestEventId);
        } else {
          app.publish('tracking/record', 'Product | AddToBasket', 'success', component.productId, jsonData.simpleBasket.requestEventId);
        }
      }

      if (jsonData.simpleBasket.lastAddedItem) {
        const lastAddedItemSku = jsonData.simpleBasket.lastAddedItem.productId;
        if (window.dataLayer.length) {
          window.dataLayer[0].lastAddedItemSku = lastAddedItemSku.toString();
        };
      }
    };
    /*
    price: string
    */
    var _formattedPrice = function(price) {

      if(price.includes('&#')) {
        price = price.split(';')[1];
      }

      return parseFloat(price).toFixed(2);
    }


    var _getLastAddedItemData = function(simpleBasket) {
      var lastAddedItem = simpleBasket.lastAddedItem;
      var data = {
        productId: lastAddedItem.productId,
        productTitle: lastAddedItem.productTitle,
        productUrl: lastAddedItem.productURL,
        productQuantity: simpleBasket.lastAddedQuantity,
        productQuantityRequested: simpleBasket.lastAddedQuantityRequested,
        productPrice: lastAddedItem.price,
        basketTotalPrice: simpleBasket.totalprice,
        recommendations: simpleBasket.recommendations,
        basketTotalItems: simpleBasket.totalUnits,
        message: simpleBasket.message,
        lastAddedMaxPerOrder: lastAddedItem.maxPerOrder,
        loyaltySchemePoints: simpleBasket.loyaltySchemePoints,
        subscriptionContractId: lastAddedItem.subscriptionContractId,
        worthValue: lastAddedItem.worthValue,
        range: lastAddedItem.range
      };

      let sku = "";
      if(lastAddedItem.externalSku) {
        sku = lastAddedItem.externalSku;
      } else {
        sku = lastAddedItem.productId;
      }

      if(component.parentElement.hasAttribute(component.config.dataAttributes.hasEnhancedEcomAtt)) {
        enhancedEcom.addToBasket(siteObj.currencyType, lastAddedItem.productTitle, sku, lastAddedItem.price, simpleBasket.lastAddedQuantity, lastAddedItem.brand, component.fulfilmentType, lastAddedItem.inStockWarehouse, lastAddedItem.collectionType, lastAddedItem.category, lastAddedItem.deliverable, lastAddedItem.range);
      }

      var imageURL = '';
      if (lastAddedItem.productImages && lastAddedItem.productImages.largeproduct) {
        imageURL = lastAddedItem.productImages.largeproduct;
      }

      if (component.config.personalisationState.message) {
        data.personalisationPhrase = component.config.personalisationState.message;
      }

      if (component.config.personalisationState.name) {
        data.personalisationName = component.config.personalisationState.name;
      }

      data.productImageUrl = imageURL;
      return data;
    };

    var _querystringifyStateObject = function(stateObject) {
      var params = [];
      for (var param in stateObject) {
        if (stateObject.hasOwnProperty(param)) {
          var value = stateObject[param];
          if (param.indexOf('variation') === 0 && param !== 'variationLength') {
            value = param + ':' + value;
          }
          params.push(param + '=' + value);
        }
      }
      return params.join('&');
    };

    var _createStateObject = function() {

      var stateObject = {
        productId: component.productId,
        siteId: component.siteId,
        siteDefaultLocale: component.defaultLocale,
        siteSubsiteCode: component.subsiteCode,
        variationLength: null,
        quantity: null,
        fromWishlist: component.fromWishlist,
      };

      if (component.context) {
        stateObject.rctxt = component.context;
      }

      if (component.config.personalisationState.message) {
        stateObject.CocaColaPersonalisationPhrase = encodeURIComponent(component.config.personalisationState.message);
      }

      if (component.config.personalisationState.name) {
        stateObject.CocaColaPersonalisationName = encodeURIComponent(component.config.personalisationState.name);
      }

      if (component.subscriptionContractId) {
        stateObject.subscriptionContractId = component.subscriptionContractId;
        // to have in the experience object when only the param is used
        stateObject.enableSubscribeAndSave = true;
      }

      if (component.parentElement.hasAttribute(component.config.dataAttributes.hasFulfilmentMethod)) {
        stateObject.fulfilmentType = component.fulfilmentType;
        stateObject.storeID = component.storeID;
      }

      if (component.hasProductPersonalisation()) {
        component.appendPersonalisationData(stateObject);
      }

      var transformedStateObject = component.transformVariations(stateObject);
      transformedStateObject = component.transformQuantity(transformedStateObject);

      return transformedStateObject;
    };

    var _appendPersonalisationData = function(stateObject) {
      if (!component.getIsPersonalisationTab()) {
        stateObject.personalisedProduct = 'false';
      } else {
        stateObject.personalisedProduct = 'true';
        const fieldDataElements = document.querySelectorAll(component.config.selectors.personalisationInputField);
        let fieldName = '';
        fieldDataElements.forEach((el) => {
          fieldName = el.getAttribute(component.config.dataAttributes.personalisationFieldName);
          if (el.value !== '' && fieldName !== '') {
            stateObject[fieldName] = encodeURIComponent(el.value);
          }
          fieldName = '';
        });
        const selectedFontElemContainer = document.querySelector(component.config.selectors.personalisationDropdownButton);
        if (selectedFontElemContainer) {
          stateObject.personalisationFontId = selectedFontElemContainer.getAttribute(component.config.dataAttributes.fontId);
        }
      }
    }

    var _transformVariations = function(stateObject) {
      var variationsData = component.variationsObject;

      if (variationsData && variationsData.variations) {
        stateObject.variationLength = variationsData.variationLength;

        for (var i = 0; i < variationsData.variations.length; i++) {
          var variationValue = variationsData.variations[i];
          stateObject[component.config.stateObject.variationTitle + (i + 1)] = variationValue;
        }
      }

      return stateObject;
    };

    var _transformQuantity = function(stateObject) {
      stateObject.quantity = (component.quantity) ? component.quantity.value : 1;
      return stateObject;
    };

    var _egiftAddToBasket = function() {
      app.publish(component.config.subscribeChannels.eGiftAddToBasket, '');
    };

    var _buildVariations = function () {
      const variationData = {
        variationLength: component.allVariations.length,
        variations: []
      };

      for (var i = 0; i < component.allVariations.length; i++) {
        var thisVariationDropdown = component.allVariations[i];
        if (thisVariationDropdown.getAttribute('data-variation-id') === '-1') {
          return {
            variationLength: component.allVariations.length,
            variations: []
          };
        }
        variationData.variations.push(thisVariationDropdown.getAttribute('data-variation-id'));
      }

      return variationData;
    };

    const _subscriptionContractsChangeContractId = (contractId) => {
      if (component.parentElement.hasAttribute(component.config.dataAttributes.subscriptionContractId)) {
        component.parentElement.setAttribute(component.config.dataAttributes.subscriptionContractId, contractId);
        component.subscriptionContractId = contractId;
      }
    };

    const _subscribeAndSaveAddToBasket = (disableAddToBasket, currentAttempt = 0) => {
      if (!component.quantity || !component.variationsObject) {
        if (currentAttempt < 10) {
          currentAttempt++;
          setTimeout(() => component.subscribeAndSaveAddToBasket(disableAddToBasket, currentAttempt), 10);
        }
      } else {
        let disableButton = disableAddToBasket || !component.canAdd();

        disableButton ? component.disableAddToBasket() : component.enableAddToBasket();
        app.publish(component.config.publishChannels.productQuantityDisable, disableButton);
      }
    };

    const _handlePersonalisationStateUpdate = ({inputIdentifier, state}) => {
      if(state.data !== "" && state.isValid){
        component.config.personalisationState[inputIdentifier] = state.data;
      }
      else {
        component.config.personalisationState[inputIdentifier] = null;
      }

      if (component.canAdd()) component.enableAddToBasket();
      else component.disableAddToBasket();
    };

    const _isValidPersonalisationState = () => {
      return !(!component.config.personalisationState.message && !component.config.personalisationState.name);
    };

    component.init = _init;
    component.config = _config;
    component.variationReceived = _variationReceived;
    component.quantityReceived = _quantityReceived;
    component.canAdd = _canAdd;
    component.enableAddToBasket = _enableAddToBasket;
    component.disableAddToBasket = _disableAddToBasket;
    component.checkSubscribeMessages = _checkSubscribeMessages;
    component.triggerAddToBasket = _triggerAddToBasket;
    component.triggerAddStraightToBasket = _triggerAddStraightToBasket;
    component.createStateObject = _createStateObject;
    component.transformVariations = _transformVariations;
    component.transformQuantity = _transformQuantity;
    component.querystringifyStateObject = _querystringifyStateObject;
    component.postAddToBasket = _postAddToBasket;
    component.addToBasketSuccess = _addToBasketSuccess;
    component.addToBasketError = _addToBasketError;
    component.getLastAddedItemData = _getLastAddedItemData;
    component.eGiftReceived = _eGiftReceived;
    component.eGiftError = _eGiftError;
    component.egiftAddToBasket = _egiftAddToBasket;
    component.customiseiRazor = _customiseiRazor;
    component.buildVariations = _buildVariations;
    component.subscriptionContractsChangeContractId = _subscriptionContractsChangeContractId;
    component.subscribeAndSaveAddToBasket = _subscribeAndSaveAddToBasket;
    component.appendPersonalisationData = _appendPersonalisationData;
    component.handlePersonalisationStateUpdate = _handlePersonalisationStateUpdate;
    component.isValidPersonalisationState = _isValidPersonalisationState;
    component.scrollToVariantSelector = _scrollToVariantSelector;
    component.showErrorMessage = _showErrorMessage;
    component.formattedPrice = _formattedPrice;
  };

  return productAddToBasket;
});
